import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { IKitGetReq, TKitGetRep } from '../../lib-shared/api/Kit.Type';
import {
  IPickupGetIdReq,
  IPickupGetPickupIdReq, IPickupPostIdReq, IPickupPutIdReq,
  TPickupGetIdRep,
  TPickupGetPickupIdRep, TPickupPostIdRep, TPickupPutIdRep
} from '../../lib-shared/api/Pickup.Type';
import {
  IUserPostChangeForgottenPasswordReq,
  TUserPostChangeForgottenPasswordRep
} from '../../lib-shared/api/User.Type';
import { IOrderGetKitBatchReq, TOrderGetKitBatchRep } from '../../lib-shared/api/Order.Type';

@Injectable({
  providedIn: 'root'
})
export class ApiPublicService {
  private url = ApiPublicService.generateUrl(environment.api_url);

  constructor(
    private http: HttpClient
  ) {
  }

  static generateUrl(base: string) {
    return `${base}`;
  }

  setUrl(base: string) {
    this.url = ApiPublicService.generateUrl(base);
  }


  getAddressIsManaged(params: { postalCode: string }) {
    return this.http.get(`${this.url}/address/ismanaged`, {
      params: new HttpParams({fromObject: params})
    });
  }

  getDeadlineList() {
    return this.http.get(`${this.url}/deadline`);
  }

  getKit(params: IKitGetReq) {
    return this.http.get<TKitGetRep>(`${this.url}/kit`);
  }

  postOrderKitBatch(params: { pickupId: string, kitList: { kitId: number, quantity: number }[] }) {
    return this.http.post(`${this.url}/order/kitbatch`, params);
  }

  getOrderKitBatch(params: IOrderGetKitBatchReq) {
    return this.http.get<TOrderGetKitBatchRep>(`${this.url}/order/kitbatch`, {
      // as any is here for retro compatibility with old version of angular
      params: new HttpParams({fromObject: params as any}),
    });
  }

  putOrderKitOrderIdEnabled(orderId: number) {
    return this.http.put(`${this.url}/orderkit/order/${orderId}/enabled`, null);
  }

  postPickup(params: IPickupPostIdReq) {
    return this.http.post<TPickupPostIdRep>(`${this.url}/pickup`, params);
  }

  putPickupId(id: string, params: IPickupPutIdReq) {
    return this.http.put<TPickupPutIdRep>(`${this.url}/pickup/${id}`, params);
  }

  getPickupPickupId(id: string, params: IPickupGetPickupIdReq) {
    return this.http.get<TPickupGetPickupIdRep>(`${this.url}/pickup/pickup/${id}`);
  }

  getPickupId(id: string, params: IPickupGetIdReq) {
    return this.http.get<TPickupGetIdRep>(`${this.url}/pickup/${id}`);
  }

  getShopList() {
    return this.http.get(`${this.url}/shop`);
  }

  postUserChangeForgottenPassword(params: IUserPostChangeForgottenPasswordReq) {
    return this.http.post<TUserPostChangeForgottenPasswordRep>(`${this.url}/user/changeforgottenpassword`, params);
  }

  postUserForgottenPassword(data: { email: string }) {
    return this.http.post(`${this.url}/user/forgottenpassword`, data);
  }
}
