import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { PRICEMULTIPLIER } from '../../../../lib/lib-shared/defines';
import { ShippingPriceType } from '../../../../lib/lib-shared/types/ShippingPriceType';

@Component({
  selector: 'lm-shipping-price-edit-modal',
  templateUrl: './shipping-price-edit-modal.component.html',
  styleUrls: ['./shipping-price-edit-modal.component.scss']
})
export class ShippingPriceEditModalComponent implements OnInit {
  public PRICEMULTIPLIER = PRICEMULTIPLIER;
  public ShippingPriceType = ShippingPriceType;

  public userTypeId;
  public shippingPrice;

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      type: [this.shippingPrice ? this.shippingPrice.type : null, Validators.required],
      diff: [this.shippingPrice ? this.shippingPrice.diff : false, Validators.required],
      start: [this.shippingPrice ? this.shippingPrice.start / PRICEMULTIPLIER : null, Validators.required],
      end: [this.shippingPrice ? this.shippingPrice.end / PRICEMULTIPLIER : null, Validators.required],
      price: [this.shippingPrice ? this.shippingPrice.price / PRICEMULTIPLIER : null, Validators.required],
      orderException: [this.shippingPrice ? this.shippingPrice.orderException : null, Validators.required],
    });
  }

  onDelete() {
    this.apiLavandierService.deleteShippingPrice(this.shippingPrice.id)
      .subscribe((() => this.activeModal.close()));
  }

  onValidate() {
    if (this.shippingPrice) {
      this.apiLavandierService.putShippingPrice(this.shippingPrice.id, {
        type: this.form.value.type,
        diff: this.form.value.diff,
        start: this.form.value.start * PRICEMULTIPLIER,
        end: this.form.value.end * PRICEMULTIPLIER,
        price: this.form.value.price * PRICEMULTIPLIER,
        orderException: this.form.value.orderException,

      })
        .subscribe((() => this.activeModal.close()));

    } else {
      this.apiLavandierService.postShippingPrice({
        userTypeId: this.userTypeId,
        type: this.form.value.type,
        diff: this.form.value.diff,
        start: this.form.value.start * PRICEMULTIPLIER,
        end: this.form.value.end * PRICEMULTIPLIER,
        price: this.form.value.price * PRICEMULTIPLIER,
        orderException: this.form.value.orderException,
      })
        .subscribe((() => this.activeModal.close()));
    }
  }
}
