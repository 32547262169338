import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { IArticleOptionPrice, IArticlePrice } from '../../../../lib/lib-ngx/web-services/api-lavandier.service.type';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { PRICEMULTIPLIER, VATType } from '../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-article-prices',
  templateUrl: './article-prices.component.html',
  styleUrls: ['./article-prices.component.scss']
})
export class ArticlePricesComponent implements OnInit {
  public PRICEMULTIPLIER = PRICEMULTIPLIER;
  public VATType = VATType;

  public articleId = null;
  public articleDetails = null;
  public articlePriceForm: FormGroup;
  public articleFamilyMap = new Map();
  public articleOptionTypeMap = new Map();
  public userTypeMap = new Map();
  public articlePriceToDeleteList = [];
  public articleOptionPriceToDeleteList = [];

  constructor(
    private apiLavandierService: ApiLavandierService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.articleId = param.id;
      this.resetData();
      this.loadData();
    });
  }

  resetData() {
    this.articlePriceForm = this.fb.group({});
    this.articleDetails = null;
    this.articleFamilyMap = new Map();
    this.articleOptionTypeMap = new Map();
    this.userTypeMap = new Map();
    this.articlePriceToDeleteList = [];
    this.articleOptionPriceToDeleteList = [];
  }

  loadData() {
    return forkJoin([
      this.apiLavandierService.getArticleFamilyList(),
      this.apiLavandierService.getArticleOptionTypeList(),
      this.apiLavandierService.getUserTypeList(),
      this.apiLavandierService.getArticle(this.articleId)
    ])
      .pipe(
        map(([articleFamilyList, articleOptionTypeList, userTypeList, articleDetails]: [Object[], Object[], Object[], Object]) => {
          return [
            ListToMap.convert(articleFamilyList),
            ListToMap.convert(articleOptionTypeList),
            ListToMap.convert(userTypeList),
            articleDetails,
          ];
        })
      )
      .subscribe(([articleFamilyList, articleOptionTypeList, userTypeList, articleDetails]: [Map<number, Object>, Map<number, Object>, Map<number, Object>, Object]) => {
        this.articleFamilyMap = articleFamilyList;
        this.articleOptionTypeMap = articleOptionTypeList;
        this.userTypeMap = userTypeList;
        this.articleDetails = articleDetails;
        this.initArticleDetails();
      });
  }

  initArticleDetails() {
    this.articleDetails['articleOptionMap'] = new Map();
    this.articleDetails.articleOptions.forEach((articleOption) => {
      if (!this.articleDetails.articleOptionMap.get(articleOption.articleOptionTypeId)) {
        this.articleDetails.articleOptionMap.set(articleOption.articleOptionTypeId, []);
      }
      this.articleDetails.articleOptionMap.get(articleOption.articleOptionTypeId).push(articleOption);
    });

    this.articleDetails['articlePriceMap'] = new Map();
    this.articleDetails.articlePrices.forEach((articlePrice) => {
      if (!this.articleDetails.articlePriceMap.get(articlePrice.userTypeId)) {
        this.articleDetails.articlePriceMap.set(articlePrice.userTypeId, articlePrice);
      }
    });

    this.articleDetails['articleOptionPriceMap'] = new Map();
    this.articleDetails.articleOptionPrices.forEach((articleOptionPrice) => {
      if (!this.articleDetails.articleOptionPriceMap.get(articleOptionPrice.userTypeId)) {
        this.articleDetails.articleOptionPriceMap.set(articleOptionPrice.userTypeId, new Map());
      }
      if (!this.articleDetails.articleOptionPriceMap.get(articleOptionPrice.userTypeId).get(articleOptionPrice.articleOptionId)) {
        this.articleDetails.articleOptionPriceMap.get(articleOptionPrice.userTypeId).set(articleOptionPrice.articleOptionId, articleOptionPrice);
      }
    });
    this.buildForm();
  }

  buildForm() {
    this.articleFamilyMap.get(this.articleDetails.articleFamilyId).userTypes.forEach((userType) => {
      this.articlePriceForm.addControl(userType.id, this.fb.group({}));
      const articleUserTypeForm = this.articlePriceForm.get(userType.id.toString()) as FormGroup;

      const articleValue = this.articleDetails.articlePriceMap.get(userType.id);
      articleUserTypeForm.addControl('article', this.fb.group({
        price: [articleValue.price / PRICEMULTIPLIER, Validators.min(0)],
        vat: [articleValue.vat]
      }));

      articleUserTypeForm.addControl('option', this.fb.group({}));
      this.articleDetails.articleOptions.forEach((articleOption) => {
        const articleOptionForm = articleUserTypeForm.get('option') as FormGroup;
        const articleOptionValue = this.articleDetails.articleOptionPriceMap.get(userType.id).get(articleOption.id);
        articleOptionForm.addControl(articleOption.id, this.fb.group({
          price: [articleOptionValue.price / PRICEMULTIPLIER, Validators.min(0)],
          vat: [articleOptionValue.vat]
        }));
      });
    });
  }

  /**
   * Method called to add and article price to articlePriceToDeleteList and patch the form value to null
   * @param articlePrice
   */
  addArticlePriceToDelete(articlePrice) {
    this.articlePriceToDeleteList.push(articlePrice);
    const articlePriceForm = this.articlePriceForm.get(articlePrice.userTypeId.toString()).get('article') as FormGroup;
    articlePriceForm.patchValue({
      price: 0,
      vat: 0
    });
  }

  /**
   * Method called to add an article option price to articleOptionPriceToDeleteList and patch the form value to null
   * @param articleOptionPrice
   */
  addArticleOptionPriceToDelete(articleOptionPrice) {
    this.articleOptionPriceToDeleteList.push(articleOptionPrice);
    const articlePriceForm = this.articlePriceForm.get(articleOptionPrice.userTypeId.toString()).get('option').get(articleOptionPrice.articleOptionId.toString()) as FormGroup;
    articlePriceForm.patchValue({
      price: 0,
      vat: 0
    });
  }

  /**
   * Method called after a click to validate the article prices and article option prices
   * It'll delete every articlePrice and articleOptionPrice in articlePriceToDeleteMap and articleOptionPriceToDeleteMap
   * It'll create or update, if price or vat value changed
   */
  onValidateArticlePrices() {
    this.articlePriceToDeleteList.forEach(article => {
      if (article.fromUserTypeId === null) {
        this.onDeleteArticlePrice(article.id);
      }
    });
    this.articlePriceToDeleteList = [];

    this.articleOptionPriceToDeleteList.forEach(articleOption => {
      if (articleOption.fromUserTypeId === null) {
        this.onDeleteArticleOptionPrice(articleOption.id);
      }
    });
    this.articleOptionPriceToDeleteList = [];

    this.articleFamilyMap.get(this.articleDetails.articleFamilyId).userTypes.forEach((userType) => {
      const articleValue = this.articleDetails.articlePriceMap.get(userType.id);
      const articleForm = this.articlePriceForm.value[userType.id]['article'];
      if (articleValue.price !== articleForm.price * PRICEMULTIPLIER || articleValue.vat !== articleForm.vat) {
        const articlePrice: IArticlePrice = {
          userTypeId: userType.id,
          articleId: articleValue.articleId,
          price: articleForm.price * PRICEMULTIPLIER,
          vat: articleForm.vat
        };
        articleValue.id === null || articleValue.fromUserTypeId !== null ? this.onNewArticlePrice(articlePrice) : this.onEditArticlePrice(articleValue.id, articlePrice);
      }

      this.articleDetails.articleOptions.forEach((articleOption) => {
        const articleOptionValue = this.articleDetails.articleOptionPriceMap.get(userType.id).get(articleOption.id);
        const articleOptionForm = this.articlePriceForm.value[userType.id]['option'][articleOption.id];
        if (articleOptionValue.price !== articleOptionForm.price * PRICEMULTIPLIER || articleOptionValue.vat !== articleOptionForm.vat) {
          const articleOptionPrice: IArticleOptionPrice = {
            userTypeId: userType.id,
            articleId: articleOptionValue.articleId,
            articleOptionId: articleOption.id,
            price: articleOptionForm.price * PRICEMULTIPLIER,
            vat: articleOptionForm.vat
          };
          articleOptionValue.id === null || articleOptionValue.fromUserTypeId !== null ? this.onNewArticleOptionPrice(articleOptionPrice) : this.onEditArticleOptionPrice(articleOptionValue.id, articleOptionPrice);
        }
      });
    });
  }

  /**
   * Method called to add a new article price
   * @param articlePrice
   */
  onNewArticlePrice(articlePrice: IArticlePrice) {
    this.apiLavandierService.postArticlePrice(articlePrice)
      .subscribe(() => this.ngOnInit());
  }

  /**
   * Method called to edit an article price
   * @param articleId
   * @param articlePrice
   */
  onEditArticlePrice(articleId, articlePrice: IArticlePrice) {
    this.apiLavandierService.putArticlePrice(articleId, articlePrice)
      .subscribe(() => this.ngOnInit());
  }

  /**
   * Method called to delete an article price by his id
   * @param articlePriceId
   */
  onDeleteArticlePrice(articlePriceId) {
    this.apiLavandierService.deleteArticlePrice(articlePriceId)
      .subscribe(() => this.ngOnInit());
  }

  /**
   * Method called to add a new article option price
   * @param articleOptionPrice
   */
  onNewArticleOptionPrice(articleOptionPrice: IArticleOptionPrice) {
    this.apiLavandierService.postArticleOptionPrice(articleOptionPrice)
      .subscribe(() => this.ngOnInit());
  }

  /**
   * Method called to edit an article option price
   * @param articleOptionPriceId
   * @param articleOptionPrice
   */
  onEditArticleOptionPrice(articleOptionPriceId, articleOptionPrice: IArticleOptionPrice) {
    this.apiLavandierService.putArticleOptionPrice(articleOptionPriceId, articleOptionPrice)
      .subscribe(() => this.ngOnInit());
  }

  /**
   * Method called to delete an article option price by his id
   * @param articleOptionPriceId
   */
  onDeleteArticleOptionPrice(articleOptionPriceId) {
    this.apiLavandierService.deleteArticleOptionPrice(articleOptionPriceId)
      .subscribe(() => this.ngOnInit());
  }
}
