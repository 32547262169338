export enum OrderStatusStatus {
  CREATE = 'CREATE',

  PICKUP_SENT = 'PICKUP_SENT',

  FACTORY_RECEIVED = 'FACTORY_RECEIVED',
  FACTORY_STOW = 'FACTORY_STOW',
  FACTORY_PACK = 'FACTORY_PACK',

  PICKUP_RECEIVED = 'PICKUP_RECEIVED',

  FINISH = 'FINISH',

  // todo delete old statuses
  PICKUPASSIGNED = 'PICKUPASSIGNED',
  PICKUPUNASSIGNED = 'PICKUPUNASSIGNED',
  PICKUPSTARTED = 'PICKUPSTARTED',
  PICKUPDONE = 'PICKUPDONE',
  STOW = 'STOW',
  DELIVERYASKED = 'DELIVERYASKED',
  DELIVERYASSIGNED = 'DELIVERYASSIGNED',
  DELIVERYUNASSIGNED = 'DELIVERYUNASSIGNED',
  DELIVERYSTARTED = 'DELIVERYSTARTED',
  DELIVERYDONE = 'DELIVERYDONE',
  CANCEL = 'CANCEL',
}
