import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, of } from 'rxjs';

import { formatTimeSlots } from '../../../../lib/lib-ngx/utils/FormatTimeSlots';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { OrderAddressType } from '../../../../lib/lib-shared/types/OrderAddressType';
import { Error } from '../../../../lib/lib-ngx/utils/error';
import { ErrorService } from '../../../../lib/lib-ngx/services/error.service';
import {
  AddOrderTransportCustomModalComponent
} from '../../modals/add-order-transport-custom-modal/add-order-transport-custom-modal.component';
import { ApiPublicService } from '../../../../lib/lib-ngx/web-services/api-public.service';

@Component({
  selector: 'lm-schedule-order',
  templateUrl: './schedule-order.component.html',
  styleUrls: ['./schedule-order.component.scss']
})
export class ScheduleOrderComponent implements OnInit {

  @Input() public incidentId = null;
  @Input() public addressUseOnPickup = null;
  @Input() public userAddressList = [];
  @Input() public shopMap = new Map();
  public userTimeSlotList = [];

  public selectedAddress = null;
  public validAddress = false;
  public selectedBillingAddress = null;
  public differentBillingAddress = false;
  public selectedDate = null;
  public selectedTime = null;
  public customSchedule = null;

  @Output() public cancel = new EventEmitter<any>();
  @Output() public schedule = new EventEmitter<any>();

  constructor(
    private apiLavandierService: ApiLavandierService,
    private apiPublicService: ApiPublicService,
    private errorService: ErrorService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    if (this.userAddressList.length) {
      const defaultAddress = this.userAddressList.find(address => address.default);
      this.selectedBillingAddress = defaultAddress ? defaultAddress : this.userAddressList[0];
      this.selectedAddress = defaultAddress ? defaultAddress : this.userAddressList[0];
      if (this.addressUseOnPickup) {
        this.selectPickupAddressAsAddress();
      }
      this.addressSelected(this.selectedAddress);
    }
  }

  selectPickupAddressAsAddress() {
    let addressFind = false;
    for (const address of this.userAddressList) {
      if (address.firstName === this.addressUseOnPickup.firstName
        && address.lastName === this.addressUseOnPickup.lastName
        && address.streetNumber === this.addressUseOnPickup.streetNumber
        && address.route === this.addressUseOnPickup.route
        && address.locality === this.addressUseOnPickup.locality
        && address.country === this.addressUseOnPickup.country
        && address.postalCode === this.addressUseOnPickup.postalCode
        && address.formattedAddress === this.addressUseOnPickup.formattedAddress
        && address.lat === this.addressUseOnPickup.lat
        && address.lng === this.addressUseOnPickup.lng
        && address.phoneNumber === this.addressUseOnPickup.phoneNumber
        && address.additional === this.addressUseOnPickup.additional) {
        addressFind = true;
        this.selectedAddress = address;
        break;
      }
    }
    if (!addressFind) {
      this.errorService.showModal(Error.PICKUP_ADDRESS_NOT_FOUND);
    }
  }

  addressSelected(newAddress) {
    this.selectedAddress = newAddress;
    forkJoin([
      this.apiPublicService.getAddressIsManaged({postalCode: newAddress.postalCode}),
      this.addressUseOnPickup ? this.apiPublicService.getAddressIsManaged({postalCode: this.addressUseOnPickup.postalCode}) : of(null),
      this.apiLavandierService.getUserTimeSlotList(this.selectedAddress.id),
    ])
      .pipe(
        map(([perimeter, pickupPerimeter, timeSlotsData]) => [
          perimeter,
          pickupPerimeter,
          formatTimeSlots(timeSlotsData),
        ])
      )
      .subscribe(([perimeter, pickupPerimeter, timeSlotsData]) => {
        if (this.addressUseOnPickup) {
          this.validAddress = false;
          if (perimeter.shopId === pickupPerimeter.shopId) {
            this.validAddress = true;
          }
        }

        this.userTimeSlotList = [];
        this.selectedDate = null;
        this.selectedTime = null;
        if (timeSlotsData.length > 0) {
          this.userTimeSlotList = timeSlotsData;

          this.selectedDate = this.userTimeSlotList[0];
          this.selectedTime = this.selectedDate.slots.length <= 0 ? null : this.selectedDate.slots[0];
        }
      });
  }

  dateSelected() {
    this.selectedTime = this.selectedDate.slots.length <= 0 ? null : this.selectedDate.slots[0];
  }

  openCustomizeOrderTransportModal() {
    const addOrderTransportMultipleModal = this.modalService.open(AddOrderTransportCustomModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    addOrderTransportMultipleModal.componentInstance.showMultipleType = false;
    addOrderTransportMultipleModal.componentInstance.shopList = Array.from(this.shopMap.values());
    addOrderTransportMultipleModal.result
      .then((data) => this.customSchedule = data)
      .catch(() => null);
  }

  onSchedule() {
    const addressesData = [
      {type: OrderAddressType.PICKUP, data: this.selectedAddress},
      {type: OrderAddressType.INVOICE, data: this.differentBillingAddress ? this.selectedBillingAddress : this.selectedAddress}
    ];
    const addressList = [];

    addressesData.forEach(function (addressData) {
      const address = {
        type: addressData.type,
        firstName: addressData.data.firstName,
        lastName: addressData.data.lastName,
        streetNumber: addressData.data.streetNumber,
        route: addressData.data.route,
        locality: addressData.data.locality,
        country: addressData.data.country,
        postalCode: addressData.data.postalCode,
        formattedAddress: addressData.data.formattedAddress,
        lat: addressData.data.lat,
        lng: addressData.data.lng,
        phoneNumber: addressData.data.phoneNumber,
        additional: addressData.data.additional
      };
      addressList.push(address);
    });

    const data = {
      shopId: this.customSchedule ? this.customSchedule.shopId : null,
      date: this.customSchedule ? this.customSchedule.date : this.selectedDate.date,
      start: this.customSchedule ? this.customSchedule.start : this.selectedTime.start,
      end: this.customSchedule ? this.customSchedule.end : this.selectedTime.end,
      selectedAddress: this.selectedAddress,
      addressList: addressList,
    };

    this.schedule.emit(data);
  }
}
